import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const Select = ({
	locatorId,
	wrapperId,
	options,
	selectedValue,
	className,
	wrapperClassName,
	onChange,
	onClick,
	isDisabled,
}) => {
	const [defaultValue, setDefaultValue] = useState(selectedValue)

	useEffect(() => {
		if (selectedValue) {
			setDefaultValue(selectedValue)
		}
	}, [selectedValue])

	const handleOnChange = (value) => {
		setDefaultValue(value)
		onChange && onChange(value)
	}

	return (
		<div
			id={wrapperId}
			className={classnames(
				'select-wrapper',
				isDisabled && 'select-wrapper--disabled',
				wrapperClassName
			)}
			onClick={(e) => {
				onClick && onClick(e)
			}}
		>
			<select
				data-locator-id={locatorId}
				value={defaultValue}
				className={classnames('select-wrapper__select', className)}
				onChange={(e) => handleOnChange(e.target.value)}
				disabled={isDisabled}
			>
				{options.map(({ value, displayValue, disabled }, index) => {
					return (
						<option key={index} value={value} disabled={disabled}>
							{displayValue}
						</option>
					)
				})}
			</select>
			<FontAwesomeIcon
				icon={['far', 'chevron-down']}
				className='select-wrapper__arrow-icon'
			/>
		</div>
	)
}

Select.defaultProps = {
	locatorId: 'not-set',
}

Select.propTypes = {
	wrapperId: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			displayValue: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	wrapperClassName: PropTypes.string,
	isDisabled: PropTypes.bool,
}
