import React, { useMemo } from 'react'
import { memo } from 'react'
import { getAppConfig } from '../../config'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Select } from 'src/components/Select'
import ButtonGroup from 'src/components/ButtonGroup'
import AdaptationsRollUpDisplayBox from './AdaptationsRollUpDisplayBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdaptationsRollUpDisplayBoxSortedRiskScores from './AdaptationsRollUpDisplayBoxSortedRiskScores'
import { Button } from 'src/components/Button'
import Explainer from 'src/components/Explainer'
import {
	SCENARIOS,
	DEFENDS,
	PDF_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT,
} from 'src/constants'
import { exportPdf } from 'src/redux/actions/assets'
import {
	isProductionEnv,
	getDefaultDefend,
	getReportButtonText,
	numberWithSuffix,
} from '../../utils'
import './styles.scss'
import {
	getUserFeatureFlags,
	getUserSettings,
} from '../../redux/selectors/user'
import {
	getAssetsPdfExportData,
	getAssetsPdfLoading,
} from '../../redux/selectors/assets'
import { fetchAdaptationLossesByAssetId } from '../../redux/actions/adaptations'
import { setProjectFilters } from '../../redux/actions/projectFilters'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { get, omit, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import AdaptationsAppliedMeasuresTable from './AdaptationsAppliedMeasuresTable'
import { getSelectedProject } from 'src/redux/selectors/projects'
import { history } from 'src/redux/store'
import { BASE_YEAR } from 'src/constants/baseParameters'
import './styles.scss'

const _AdaptationsRollUp = ({
	userFeatureFlags,
	aggregated_results,
	sorted_risk_scores,
	current_asset,
	adaptation_wise_results,
	userSettings,
	fetchAdaptationLossesByAssetId,
	setProjectFilters,
	assetsPdfExportData,
	isAssetsPdfLoading,
	exportPdf,
	sortedRiskScoresPreAdapt,
	selectedProject,
}) => {
	const location = useLocation()
	const history = useHistory()
	const appConfig = getAppConfig()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	function getRatingAndColor(num) {
		const mapping = {
			1: ['A', 'rgb(167, 167, 166)'],
			2: ['B', 'rgb(246, 180, 185)'],
			3: ['C', 'rgb(239, 120, 128)'],
			4: ['D', 'rgb(227, 6, 22)'],
			5: ['E', 'rgb(184, 24, 26)'],
			6: ['F', 'rgb(105, 20, 16)'],
		}

		return mapping[num] || ['A', 'rgb(167, 167, 166)']
	}

	const defaultScenario = queryParams['scenario']
	const defaultDefend = queryParams['defended']

	const pdfExportPercentage = assetsPdfExportData?.percentAdapt

	const handleQueryParamsChange = (key, updatedParams) => {
		let newParams = { ...queryParams, ...updatedParams }
		if (key === 'project' && get(newParams, 'assetId')) {
			newParams = omit(newParams, ['assetId'])
		}

		history.replace({
			pathname: location.pathname,
			search: qs.stringify({ ...newParams }),
		})
	}

	const defends = useMemo(
		() =>
			DEFENDS.map(({ value, displayValue }) => ({
				value,
				displayValue,
				type: 'secondary',
				isDisabled: !appConfig.featureFlags.isDefendsButtonEnabled,
			})),
		[appConfig.featureFlags.isDefendsButtonEnabled]
	)

	const selectedDefend = useMemo(() => {
		const searchValue = appConfig.featureFlags.isDefendsButtonEnabled
			? defaultDefend
			: getDefaultDefend().value
		return DEFENDS.find(({ value }) => value === searchValue)
	}, [defaultDefend, appConfig.featureFlags.isDefendsButtonEnabled])

	const isProductionMode = isProductionEnv()
	const enableMidRcps = userFeatureFlags['enable-mid-rcps'] || false
	const enableSsps = userFeatureFlags['enable-ssps'] || false

	const updatedScenarios = SCENARIOS.map((scenario) => {
		if (
			isProductionMode &&
			enableMidRcps &&
			(scenario.value.includes('rcp45') || scenario.value.includes('rcp60'))
		) {
			return { ...scenario, disabled: false }
		}
		if (enableSsps && scenario.value.includes('ssp')) {
			return { ...scenario, disabled: false }
		}
		return scenario
	})
	const scenarios = updatedScenarios

	const defaultTargetEpc = get(queryParams, 'targetEpc')
	const defaultEpcTargetYear = get(queryParams, 'epcTargetYear')
	const selectedProjectId = get(queryParams, 'projectId')
	const defended = get(queryParams, 'defended')

	const handlePdfExport = () => {
		const assetsCount = get(selectedProject, 'num_assets', 0)

		const shouldUserReceiveEmail =
			assetsCount > PDF_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT

		if (assetsPdfExportData?.adaptUrl) {
			window.open(assetsPdfExportData.adaptUrl, '_blank')
		} else {
			exportPdf({
				selectedProjectId: selectedProjectId,
				shouldUserReceiveEmail: shouldUserReceiveEmail,
				defaultScenario: defaultScenario,
				defaultYear: BASE_YEAR,
				defaultTargetEpc: defaultTargetEpc,
				defaultEpcTargetYear: defaultEpcTargetYear,
				defended: defended,
				isAdapt: true,
				selectedAssetId: current_asset.id,
			})
		}
	}

	const handleSelectScenario = (key, value) => {
		const defended = defaultDefend === 'undefended' ? false : true
		setProjectFilters(value, defended)
		fetchAdaptationLossesByAssetId(current_asset.id, value, defended)
		handleQueryParamsChange('summary', { [key]: value })
	}

	const handleSelectDefend = (key, value) => {
		const defended = value.value === 'undefended' ? false : true
		setProjectFilters(defaultScenario, defended)
		fetchAdaptationLossesByAssetId(current_asset.id, defaultScenario, defended)
		handleQueryParamsChange('summary', { [key]: value.value })
	}

	const MemoTable = memo(function MemoTable({ data, userSettings }) {
		return (
			<AdaptationsAppliedMeasuresTable
				data={data}
				userSettings={userSettings}
				locatorId={'table-adapt-rollup-applied-measures'}
			/>
		)
	})

	const totalAdaptCost = !isEmpty(adaptation_wise_results)
		? adaptation_wise_results.reduce((accumulator, currentData) => {
				return accumulator + currentData.adapt_cost
			}, 0)
		: 0

	return (
		<div className='project-adaptations-roll-up-wrapper'>
			<div
				data-locator-id={'text-adaptations-rollup-asset-id'}
				className='project-adaptations-roll-up-wrapper__asset-display'
			>
				Asset: {current_asset?.asset_id}
			</div>
			<div className='row project-adaptations-roll-up-wrapper__risk-cards'>
				<div className='col-4 project-adaptations-roll-up-wrapper__adaptations-roll-up-col-l'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<ButtonGroup
							options={defends}
							defaultValue={selectedDefend}
							onChange={(defend) => handleSelectDefend('defended', defend)}
							activeClassName={'green'}
							locatorId={'button-group-adapt-defences'}
							customClassName='project-adaptations-roll-up-wrapper__button-group'
						/>
						<Select
							options={scenarios}
							selectedValue={defaultScenario}
							onChange={(scenario) =>
								handleSelectScenario('scenario', scenario)
							}
							className='project-adaptations-roll-up-wrapper__scenario__button'
							locatorId={'select-adapt-scenario'}
						/>
					</div>
					<div
						id='estimated_pre_adaptation_loss'
						className='project-adaptations-roll-up-wrapper__card-wrapper'
					>
						<AdaptationsRollUpDisplayBox
							boxLabel={'Estimated Pre-Adaptation Loss'}
							userSettings={userSettings}
							displayValue={
								numberWithSuffix(aggregated_results?.building_loss_pre_adapt) ||
								0
							}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-pre-adaptation-building-loss-amount'}
						/>
						<Explainer
							elementId='estimated_pre_adaptation_loss'
							explainerKey='estimated_pre_adaptation_loss'
						/>
					</div>
					<div
						id='estimated_adaptation_loss_savings'
						className='project-adaptations-roll-up-wrapper__card-wrapper'
					>
						<AdaptationsRollUpDisplayBox
							boxLabel={'Estimated Loss Savings'}
							userSettings={userSettings}
							displayValue={
								numberWithSuffix(
									aggregated_results?.adapt_one_yr_loss_savings
								) || 0
							}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-building-loss-savings-amount'}
						/>
						<Explainer
							elementId='estimated_adaptation_loss_savings'
							explainerKey='estimated_adaptation_loss_savings'
						/>
					</div>
					<div
						id='estimated_post_adaptation_loss'
						className='project-adaptations-roll-up-wrapper__card-wrapper'
					>
						<AdaptationsRollUpDisplayBox
							boxLabel={'Estimated Post-Adaptation Loss'}
							userSettings={userSettings}
							displayValue={
								numberWithSuffix(
									aggregated_results?.building_loss_post_adapt
								) || 0
							}
							color={'green'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-post-adaptation-building-loss-amount'}
						/>
						<Explainer
							elementId='estimated_post_adaptation_loss'
							explainerKey='estimated_post_adaptation_loss'
						/>
					</div>
					<div
						id='estimated_total_adaptation_loss'
						className='project-adaptations-roll-up-wrapper__card-wrapper'
					>
						<AdaptationsRollUpDisplayBox
							boxLabel='Estimated Total Adaptation Cost'
							userSettings={userSettings}
							displayValue={numberWithSuffix(totalAdaptCost)}
							color={'green'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-total-cost'}
						/>
						<Explainer
							elementId='estimated_total_adaptation_loss'
							explainerKey='estimated_total_adaptation_loss'
						/>
					</div>
				</div>
				<div className='col-4 project-adaptations-roll-up-wrapper__adaptations-roll-up-col-r'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Button
							locatorId='button-generate-pdf-report'
							id='generate-report-button'
							className={classnames(
								'project-adaptations-pdf__button',
								'project-adaptations-pdf__button--report',
								isAssetsPdfLoading && 'project-adaptations-pdf__button--loading'
							)}
							onClick={() => handlePdfExport()}
							disabled={isAssetsPdfLoading}
						>
							<span
								className={classnames(
									'project-adaptations-pdf__progress-indicator',
									!isAssetsPdfLoading &&
										'project-adaptations-pdf__progress-indicator--loading-finished'
								)}
								style={{
									width: pdfExportPercentage ? `${pdfExportPercentage}%` : 0,
								}}
							/>
							<FontAwesomeIcon icon={['far', 'file-chart-column']} />
							{getReportButtonText(
								isAssetsPdfLoading,
								pdfExportPercentage,
								assetsPdfExportData,
								'adaptUrl'
							)}
						</Button>
					</div>

					<div className='project-adaptations-roll-up-wrapper__card-wrapper'>
						<AdaptationsRollUpDisplayBox
							boxLabel={'Overall Physical Risk Pre-Adapt'}
							displayValue={
								aggregated_results?.physical_risk_pre_adapt || 'No Data'
							}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-overall-physical-risk-pre-score'}
						/>
					</div>
					<div className='project-adaptations-roll-up-wrapper__card-wrapper'>
						<AdaptationsRollUpDisplayBoxSortedRiskScores
							boxLabel={'Sorted Risk Scores Pre-Adapt'}
							data={sortedRiskScoresPreAdapt}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-sorted-risk-pre-scores'}
						/>
					</div>
				</div>
				<div className='col-4 project-adaptations-roll-up-wrapper__pre-adapt-risk'>
					<div className='project-adaptations-roll-up-wrapper__card-wrapper'>
						<AdaptationsRollUpDisplayBox
							boxLabel={'Overall Physical Risk Post-Adapt'}
							displayValue={
								aggregated_results?.physical_risk_post_adapt || 'No Data'
							}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-overall-physical-risk-post-score'}
						/>
					</div>
					<div className='project-adaptations-roll-up-wrapper__card-wrapper'>
						<AdaptationsRollUpDisplayBoxSortedRiskScores
							boxLabel={'Sorted Risk Scores Post-Adapt'}
							data={sorted_risk_scores}
							color={'white'}
							getRatingAndColor={getRatingAndColor}
							locatorId={'text-adaptation-sorted-risk-post-scores'}
						/>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 project-adaptations-roll-up-wrapper__measures-box'>
					<div style={{ marginLeft: '15px', marginBottom: '15px' }}>
						Applied Adaptation Measures
					</div>
					<MemoTable
						data={adaptation_wise_results}
						userSettings={userSettings}
					/>
				</div>
			</div>
		</div>
	)
}

_AdaptationsRollUp.propTypes = {
	userFeatureFlags: PropTypes.object.isRequired,
}

export const AdaptationsRollUp = connect(
	(state) => {
		const queryParams = qs.parse(history.location.search, {
			ignoreQueryPrefix: true,
		})

		return {
			userFeatureFlags: getUserFeatureFlags(state),
			userSettings: getUserSettings(state),
			isAssetsPdfLoading: getAssetsPdfLoading(state, true),
			assetsPdfExportData: getAssetsPdfExportData(state),
			selectedProject: getSelectedProject(state, queryParams?.projectId),
		}
	},
	{ fetchAdaptationLossesByAssetId, setProjectFilters, exportPdf }
)(_AdaptationsRollUp)
