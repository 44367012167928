import { getActiveAgeCategoryDependOnRefurbishedYear } from 'src/utils/adaptations'
import {
	SELECT_ADAPTATION_CONFIG_DISPLAY,
	SET_ADAPTIVE_CAPACITY,
	SET_ADAPT_SIDEBAR_LOADING_STATE,
	SET_ADAPT_ROLLUP_LOADING_STATE,
	SET_EXPOSURE_SENSITIVITY,
	SET_INDIRECT_MEASURE,
	ADD_ADAPTATIONS,
	ADD_ADAPTATION_RESULTS,
	ADD_ADAPTATION_DIRECT_MEASURE_RESULTS,
	SET_CURRENT_ASSET,
	SET_ADAPTATION_OPTIONS,
	SET_DIRECT_MEASURE,
	CLEAR_ADAPT_DATA,
} from '../actions/adaptations'

export const INITIAL_ADAPTATIONS_PAGINATION = {
	offset: 0,
	limit: 20,
}

const default_adapt_data = {
	building_vulnerabilities: {},
	adaptation_options: {
		adaptive_capacity_options: {},
		direct_measures: {},
		indirect_measures: {},
	},
}
const DEFAULT_STATE = {
	data: undefined,
	asset_adaptations: {},
	current_asset: undefined,
	adapt_data: default_adapt_data,
	pagination: INITIAL_ADAPTATIONS_PAGINATION,
	searchValue: undefined,
	loading: false,
	pdfExportData: {
		loading: false,
		percent: 0,
		url: undefined,
	},
	paginationLoading: false,
	adaptSidebarLoading: false,
	adaptRollupLoading: false,
	display: 'exposure_sensitivity',
	displayNext: 'indirect_measures',
	displayPrevious: 'direct_adaptations',
	error: undefined,
	csvExportData: {
		loading: false,
		progressInPercentage: 0,
		url: undefined,
	},
}

export default function adaptations(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SELECT_ADAPTATION_CONFIG_DISPLAY:
			let next, previous
			if (action.payload === 'exposure_sensitivity') {
				next = 'indirect_measures'
				previous = 'direct_adaptations'
			} else if (action.payload === 'indirect_measures') {
				next = 'direct_adaptations'
				previous = 'exposure_sensitivity'
			} else if (action.payload === 'direct_adaptations') {
				next = 'exposure_sensitivity'
				previous = 'indirect_measures'
			}

			return {
				...state,
				display: action.payload,
				displayNext: next,
				displayPrevious: previous,
			}

		case SET_ADAPTATION_OPTIONS: {
			const {
				adaptive_capacity_options,
				direct_measures,
				indirect_measures,
				building_vulnerabilities,
			} = action.payload.refAdaptations

			const updatedAssetAdaptations = {
				...state.asset_adaptations,
				[`asset_${action.payload.assetId}`]: {
					...state.asset_adaptations[`asset_${action.payload.assetId}`],
					building_vulnerabilities: { ...building_vulnerabilities },
					adaptation_options: {
						adaptive_capacity_options: { ...adaptive_capacity_options },
						direct_measures: { ...direct_measures },
						indirect_measures: { ...indirect_measures },
					},
				},
			}

			return {
				...state,
				asset_adaptations: updatedAssetAdaptations,
			}
		}

		case ADD_ADAPTATION_RESULTS:
			return {
				...state,
				asset_adaptations: {
					...state.asset_adaptations,
					[`asset_${state.current_asset.id}`]: {
						...state.asset_adaptations[`asset_${state.current_asset.id}`],
						adaptation_results: { ...action.payload.data },
					},
				},
			}

		case ADD_ADAPTATION_DIRECT_MEASURE_RESULTS:
			return {
				...state,
				asset_adaptations: {
					...state.asset_adaptations,
					[`asset_${state.current_asset.id}`]: {
						...state.asset_adaptations[`asset_${state.current_asset.id}`],
						adaptation_direct_measure_results: { ...action.payload.data },
					},
				},
			}

		case ADD_ADAPTATIONS:
			if (state.asset_adaptations[`asset_${action.payload.assetId}`]) {
				return {
					...state,
				}
			} else {
				return {
					...state,
					asset_adaptations: {
						...state.asset_adaptations,
						[`asset_${action.payload.assetId}`]: action.payload.data,
					},
				}
			}

		case SET_CURRENT_ASSET:
			return {
				...state,
				current_asset: action.payload.asset,
			}

		case SET_ADAPTIVE_CAPACITY: {
			const { key, value } = action.payload
			const currentAssetKey = `asset_${state.current_asset.id}`
			const assetAdaptations = state.asset_adaptations[currentAssetKey]
			const adaptiveCapacities =
				assetAdaptations.adaptation_options.adaptive_capacity_options

			const updatedAdaptiveCapacities = {
				...adaptiveCapacities,
				[key]: {
					...adaptiveCapacities[key],
					default: value,
				},
			}

			const updatedAssetAdaptations = {
				...state.asset_adaptations,
				[currentAssetKey]: {
					...assetAdaptations,
					adaptation_options: {
						...assetAdaptations.adaptation_options,
						adaptive_capacity_options: updatedAdaptiveCapacities,
					},
				},
			}

			return {
				...state,
				asset_adaptations: updatedAssetAdaptations,
			}
		}

		case SET_INDIRECT_MEASURE: {
			const { key, value } = action.payload
			const currentAssetKey = `asset_${state.current_asset.id}`
			const assetAdaptations = state.asset_adaptations[currentAssetKey]
			const indirectMeasures =
				assetAdaptations.adaptation_options.indirect_measures

			const updatedIndirectMeasures = {
				...indirectMeasures,
				[key]: {
					...indirectMeasures[key],
					default: value,
				},
			}

			const updatedAssetAdaptations = {
				...state.asset_adaptations,
				[currentAssetKey]: {
					...assetAdaptations,
					adaptation_options: {
						...assetAdaptations.adaptation_options,
						indirect_measures: updatedIndirectMeasures,
					},
				},
			}

			return {
				...state,
				asset_adaptations: updatedAssetAdaptations,
			}
		}

		case SET_DIRECT_MEASURE: {
			const { key, value } = action.payload
			const currentAssetKey = `asset_${state.current_asset.id}`
			const assetAdaptations = state.asset_adaptations[currentAssetKey]
			const directMeasures = assetAdaptations.adaptation_options.direct_measures

			const updatedDirectMeasures = {
				...directMeasures,
				[key]: {
					...directMeasures[key],
					default: value,
				},
			}

			const updatedAssetAdaptations = {
				...state.asset_adaptations,
				[currentAssetKey]: {
					...assetAdaptations,
					adaptation_options: {
						...assetAdaptations.adaptation_options,
						direct_measures: updatedDirectMeasures,
					},
				},
			}

			return {
				...state,
				asset_adaptations: updatedAssetAdaptations,
			}
		}

		case SET_EXPOSURE_SENSITIVITY: {
			const { key, value, isSelect } = action.payload
			const currentAssetKey = `asset_${state.current_asset.id}`
			const assetAdaptations = state.asset_adaptations[currentAssetKey]
			const buildingVulnerabilities = assetAdaptations.building_vulnerabilities
			let updatedBuildingVulnerabilities
			let ageCategory

			if (key === 'refurbished_year' && value) {
				const lockedAgeCategoryValue =
					getActiveAgeCategoryDependOnRefurbishedYear({
						ageCategoryOptions:
							assetAdaptations.building_vulnerabilities.age_category.options,
						refurbishedYear: value,
					})

				ageCategory = {
					...buildingVulnerabilities.age_category,
					value: lockedAgeCategoryValue,
				}
			}

			if (isSelect) {
				updatedBuildingVulnerabilities = {
					...buildingVulnerabilities,
					[key]: {
						...buildingVulnerabilities[key],
						value: value,
					},
				}
			} else
				updatedBuildingVulnerabilities = {
					...buildingVulnerabilities,
					[key]: value,
				}

			const updatedAssetAdaptations = {
				...state.asset_adaptations,
				[currentAssetKey]: {
					...assetAdaptations,
					building_vulnerabilities: {
						...updatedBuildingVulnerabilities,
						age_category: ageCategory
							? ageCategory
							: updatedBuildingVulnerabilities.age_category,
					},
				},
			}

			return {
				...state,
				asset_adaptations: updatedAssetAdaptations,
			}
		}
		case SET_ADAPT_SIDEBAR_LOADING_STATE:
			return {
				...state,
				adaptSidebarLoading: action.payload,
			}
		case SET_ADAPT_ROLLUP_LOADING_STATE:
			return {
				...state,
				adaptRollupLoading: action.payload,
			}
		case CLEAR_ADAPT_DATA:
			return DEFAULT_STATE
		default:
			return state
	}
}
