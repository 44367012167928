export const getActiveAgeCategoryDependOnRefurbishedYear = ({
	ageCategoryOptions,
	refurbishedYear,
}) => {
	for (const range of ageCategoryOptions) {
		if (!range) continue // Skip null or undefined ranges

		const [start, end] = range.split('-').map((value) => value.trim())

		const parsedEnd = parseInt(end, 10)

		if (start === 'pre' && refurbishedYear < parsedEnd) {
			return range
		}

		if (start === 'post' && refurbishedYear > parsedEnd) {
			return range
		}

		const parsedStart = parseInt(start, 10)

		if (
			!isNaN(parsedStart) &&
			!isNaN(parsedEnd) &&
			refurbishedYear >= parsedStart &&
			refurbishedYear <= parsedEnd
		) {
			return range
		}
	}

	return null
}
