import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

function AdaptationsRollUpDisplayBox({
	boxLabel,
	userSettings,
	displayValue,
	color,
	getRatingAndColor,
	locatorId,
}) {
	return (
		<div className={`boxWrapper--${color}`} data-locator-id={locatorId}>
			<div className='boxLabel'>{boxLabel}</div>
			<div>
				{![
					'Overall Physical Risk Post-Adapt',
					'Overall Physical Risk Pre-Adapt',
				].includes(boxLabel) ? (
					<div className='displayValue'>
						{`${userSettings.preferred_currency_symbol} ${displayValue}`}
					</div>
				) : (
					<div
						className='displayValue'
						style={{ color: `${getRatingAndColor(displayValue)[1]}` }}
					>
						{getRatingAndColor(displayValue)[0]}
					</div>
				)}
			</div>
		</div>
	)
}

AdaptationsRollUpDisplayBox.defaultProps = {
	locatorId: 'not-set',
}

AdaptationsRollUpDisplayBox.propTypes = {
	displayValue: PropTypes.string,
}

export default AdaptationsRollUpDisplayBox
