import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { numberWithSuffix } from 'src/utils'
import './styles.scss'

const transformDataToRows = (data) => {
	let rows = []
	let idCounter = 1

	const processMeasures = (measures) => {
		Object.entries(measures).forEach(([_, value]) => {
			rows.push({
				id: idCounter++,
				ref_adaptation_id: value.ref_adaptation_id
					.toLowerCase()
					.replaceAll('_', ' ')
					.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
				hazard: value.relevant_hazards.join(', '),
				adaptCost: value.adapt_cost,
				oneYearROI: value.one_year_ROI,
				tenYearROI: value.ten_yr_ROI,
				oneYrLossSavings: value.one_yr_loss_savings,
				tenYrLossSavings: value.ten_yr_loss_savings,
			})
		})
	}

	if (data?.length > 0) {
		processMeasures(data)
	}
	return rows
}

function AdaptationsAppliedMeasuresTable({ data, userSettings, locatorId }) {
	const columns = [
		{
			field: 'ref_adaptation_id',
			headerName: 'Measure',
			width: 280,
			renderCell: (params) => (
				<span
					style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'normal' }}
				>
					{params.row.ref_adaptation_id}
				</span>
			),
		},
		{ field: 'hazard', headerName: 'Hazards', width: 300 },
		{
			field: 'adaptCost',
			headerName: 'Cost',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.adaptCost
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${numberWithSuffix(
					value.value
				)}`
			},
		},
		{
			field: 'oneYearROI',
			headerName: '1 YR ROI',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.oneYearROI
			},
			valueFormatter: (value) => {
				return `${parseFloat(value.value * 100).toFixed(2)}%`
			},
		},
		{
			field: 'tenYearROI',
			headerName: '10 YR ROI',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.tenYearROI
			},
			valueFormatter: (value) => {
				return `${parseFloat(value.value * 100).toFixed(2)}%`
			},
		},
		{
			field: 'oneYrLossSavings',
			headerName: '1 YR loss savings',
			type: 'number',
			width: 130,
			valueGetter: (value) => {
				return value.row.oneYrLossSavings
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${parseFloat(
					value.value
				).toFixed(0)}`
			},
		},
		{
			field: 'tenYrLossSavings',
			headerName: '10 YR loss savings',
			type: 'number',
			width: 140,
			valueGetter: (value) => {
				return value.row.tenYrLossSavings
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${parseFloat(
					value.value
				).toFixed(0)}`
			},
		},
	]
	let rows = transformDataToRows(data)
	return (
		<div
			style={{ height: 400, width: 'auto' }}
			data-locator-id={locatorId}
			className='adapt-applied-measures-table'
		>
			<DataGrid
				// TODO: Pagination looks weird, hiding it for now. Have increased the height
				hideFooterPagination={true}
				hideFooter={true}
				rowHeight={40}
				rows={rows}
				columns={columns}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '10px',
						color: '#5e5e5e',
						fontFamily:
							'"Graphik Web", "Segoe UI", "Helvetica Neue", Helvetica, Arial, "sans-serif"',
					},
					'& .MuiDataGrid-columnHeaders': {
						fontSize: '12px',
						color: '#5e5e5e',
						fontFamily:
							'"Graphik Web", "Segoe UI", "Helvetica Neue", Helvetica, Arial, "sans-serif"',
					},
				}}
			/>
		</div>
	)
}

AdaptationsAppliedMeasuresTable.defaultProps = {
	locatorId: 'not-set',
}

AdaptationsAppliedMeasuresTable.propTypes = {}

export default AdaptationsAppliedMeasuresTable
