import { numberWithCommas } from './numberWithCommas'

export const numberWithSuffix = (number) => {
	if (typeof number === 'number') {
		if (number === 0) {
			return 0
		} else if (number > 0 && number < 999) {
			return Math.round(number / 10) * 10
		} else if (number < 9999) {
			return numberWithCommas(Math.round(number / 100) * 100)
		} else if (number <= 99999) {
			return Math.round(number / 100) / 10 + 'k'
		} else if (number < 999999) {
			return Math.round(number / 1000) + 'k'
		} else {
			return Math.round(number / 100000) / 10 + 'm'
		}
	}

	return null
}
